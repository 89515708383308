export const INTENT_ACCEPT = "accept";
export const INTENT_COUNTER = "counter";
export const INTENT_DECLINE = "decline";
export const INTENT_SELFDELIVERY = "selfdelivery";
export const INTENT_CONNECT8DELIVERY = "connect8delivery";
export const INTENT_SEND = "send";
export const INTENT_ADDFAVORITE = "addfavorite";
export const INTENT_SHOWDETAILS = "showdetails";
export const INTENT_SAVENEWQUICKFILTER = "saveNewQuickFilter";

export const FORMDATA_USERTRADEPRODUCTID = "usertradeproductid";
export const FORMDATA_USERTRADEPRODUCTIDLOGISTIC = "usertradeproductlogisticid";
export const FORMDATA_TRADEPRODUCTID = "tradeproductid";
export const FORMDATA_COMPANYID = "companyid";
export const FORMDATA_CREATORUSERID = "creatoruserid";

export const MESSAGE_PLACEHOLDER_USERNAME = "%USERNAME%";
export const MESSAGE_PLACEHOLDER_PRODUCT = "%PRODUCT%";
